import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import jwtService from '../../auth/services/jwtService';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

const schema = yup.object().shape({
    email: yup.string().email('You must enter a valid email').required('You must enter a email'),
});

const defaultValues = {
    email: '',
};

function ForgotPassPage() {
    const { control, formState, handleSubmit, setError, reset } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });
    const [disable, setDisable] = useState(false)
    const { isValid, dirtyFields, errors } = formState;

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const onSubmit = async ({ email }) => {
        try {
            setDisable(true)
            const response = await jwtService.forgotPassword(email);

            if (response?.data?.type === 'success') {
                dispatch(showMessage({ message: response?.data?.message || "Reset Password Email sent.", variant: "success" }))
                setDisable(false)
                navigate('/sign-in')
            }
        } catch (error) {
            setDisable(false)
            const errorMessage = error?.response?.data?.error || "Something Went Wrong";
            dispatch(showMessage({ message: errorMessage, variant: "error" }));
        }
    };

    return (
        <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 md:p-32">
            <Paper className="flex w-full sm:w-auto min-h-full sm:min-h-auto md:w-full md:max-w-6xl rounded-0 sm:rounded-2xl sm:shadow overflow-hidden">
                <div className="w-full sm:w-auto py-32 px-16 sm:p-48 md:p-64 ltr:border-r-1 rtl:border-l-1">
                    <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
                        <img className="w-48" src="assets/images/logo/logo.svg" alt="logo" />

                        <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
                            Forgot password?
                        </Typography>
                        <div className="flex items-baseline mt-2 font-medium">
                            <Typography>Fill the form to reset your password</Typography>
                        </div>

                        <form
                            name="registerForm"
                            noValidate
                            className="flex flex-col justify-center w-full mt-32"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="mb-24"
                                        label="Email"
                                        type="email"
                                        error={!!errors.email}
                                        helperText={errors?.email?.message}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                )}
                            />

                            <Button
                                variant="contained"
                                color="secondary"
                                className=" w-full mt-4"
                                aria-label="Register"
                                disabled={_.isEmpty(dirtyFields) || !isValid || disable}
                                type="submit"
                                size="large"
                            >
                                Send reset link
                            </Button>

                            <Typography className="mt-32 text-md font-medium" color="text.secondary">
                                <span>Return to</span>
                                <Link className="ml-4" to="/sign-in">
                                    sign in
                                </Link>
                            </Typography>
                        </form>
                    </div>
                </div>

                <Box
                    className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
                    sx={{ backgroundColor: 'primary.main' }}
                >
                    <svg
                        className="absolute inset-0 pointer-events-none"
                        viewBox="0 0 960 540"
                        width="100%"
                        height="100%"
                        preserveAspectRatio="xMidYMax slice"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <Box
                            component="g"
                            sx={{ color: 'primary.light' }}
                            className="opacity-20"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="100"
                        >
                            <circle r="234" cx="196" cy="23" />
                            <circle r="234" cx="790" cy="491" />
                        </Box>
                    </svg>
                    <Box
                        component="svg"
                        className="absolute -top-64 -right-64 opacity-20"
                        sx={{ color: 'primary.light' }}
                        viewBox="0 0 220 192"
                        width="220px"
                        height="192px"
                        fill="none"
                    >
                        <defs>
                            <pattern
                                id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                                x="0"
                                y="0"
                                width="20"
                                height="20"
                                patternUnits="userSpaceOnUse"
                            >
                                <rect x="0" y="0" width="4" height="4" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
                    </Box>

                    <div className="z-10 relative w-full max-w-2xl">
                        <div className="text-7xl font-bold leading-none text-grey-800">
                            <div>Welcome to</div>
                            <div>MPMedia!</div>
                        </div>
                        <div className="mt-24 text-lg tracking-tight leading-6 text-grey-800">
                            Powerful and intuitive all-in-one monitoring console for remote signage devices.
                        </div>
                    </div>
                </Box>
            </Paper>
        </div>
    );
}

export default ForgotPassPage;
