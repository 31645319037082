import authRoles from '../../auth/authRoles';
import ForgotPassPage from './ForgotPassPage';

const ForgotPassConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: 'forgot-password',
            element: <ForgotPassPage />,
        },
    ],
};

export default ForgotPassConfig;
