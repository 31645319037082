import FuseNavigation from '@fuse/core/FuseNavigation';
import clsx from 'clsx';
import { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation } from 'app/store/fuse/navigationSlice';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import { selectUser } from 'app/store/userSlice';

function Navigation(props) {
  const user = useSelector(selectUser);
  const navigation = useSelector(selectNavigation);

  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const dispatch = useDispatch();


  return useMemo(() => {
    var arrays = [];
    function handleItemClick(item) {
      if (isMobile) {
        dispatch(navbarCloseMobile());
      }
    }
    if (user?.permission) {
      navigation?.map((v) => {
        if (v?.id == 'master-angency') { arrays.push(v) }
        if (v?.id == 'agency-master-configs') {
          let obj = v
            // let obj = v;
          const permissions = JSON.parse(user?.permission) || [];
          
          // Check for individual permissions
          const hasCradlePointPermission = permissions.includes("cradlePoint");
          const hasIpConfigurationsPermission = permissions.includes("ipConfigurations");
          
          let childRoutes = obj.children;
          
          // If the user doesn't have permission for 'cradlePoint', filter it out
          if (!hasCradlePointPermission) {
            childRoutes = childRoutes?.filter((e) => e?.id !== "cradlePoint");
          }
          
          // If the user doesn't have permission for 'ipConfigurations', filter it out
          if (!hasIpConfigurationsPermission) {
            childRoutes = childRoutes?.filter((e) => e?.id !== "ipConfigurations");
          }
          
          // If there are still valid children, update the obj and add it to the arrays
          if (childRoutes?.length > 0) {
            obj = {
              ...obj,
              children: childRoutes
            };
            arrays.push(obj);
          } else {
            // If no valid children left, you could decide if you still want to add obj to arrays
            // For now, we won't add it if both 'cradlePoint' and 'ipConfigurations' are filtered out.
            // Optionally, you could add a fallback or handle differently
            // arrays.push(obj); // Uncomment this if you want to add the obj anyway
          }   
        }
        JSON.parse(user?.permission)?.forEach((modulesName) => {
          if (v.id == modulesName) {
            arrays.push(v);
          }
        })
      })
    }
    return (
      <FuseNavigation
        className={clsx('navigation', props.className)}
        navigation={(user?.permission) ? arrays : navigation}
        layout={props.layout}
        dense={props.dense}
        active={props.active}
        onItemClick={handleItemClick}
      />
    );
  }, [dispatch, isMobile, navigation, props.active, props.className, props.dense, props.layout]);
}

Navigation.defaultProps = {
  layout: 'vertical',
};

export default memo(Navigation);
