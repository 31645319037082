import authRoles from '../../auth/authRoles';
import ResetPassPage from './ResetPassPage';

const ResetPassConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    auth: authRoles.onlyGuest,
    routes: [
        {
            path: 'reset-password',
            element: <ResetPassPage />,
        },
    ],
};

export default ResetPassConfig;
